import React, { useEffect } from "react";
import axios from "axios";
import "./home.css";
import bg from "../../assets/hero.jpeg";
import heroBanner from "../../assets/hero-banner.png";
import service_one from "../../assets/service-1.png";
import service_two from "../../assets/service-2.png";
import service_three from "../../assets/service-3.png";
import { FaCheck } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { IoLogoInstagram } from "react-icons/io";
import { IoLogoLinkedin } from "react-icons/io";
import { AiFillHome } from "react-icons/ai";
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import Kamala_height from "../../assets/Kamala_Height.jpeg";
import matri from "../../assets/matri.jpeg";
import royal from "../../assets/royal.jpeg";
import jain from "../../assets/jain.jpeg";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const hostels = [
    {
      name: "Kamla Heights",
      address: "A-74, Landmark City",
      area: "Kunhadi",
      min_rent: "13000-14000",
      imgUrl: Kamala_height,
    },
    {
      name: "Matri Kripa Residency",
      address: "B-130 Riddhi Siddhi Nagar",
      area: "Kunhadi",
      min_rent: "10000",
      imgUrl: matri,
    },
    {
      name: "Royal Residency",
      address: "F-16 Gate No- 9",
      area: "Kunhadi",
      min_rent: "12000",
      imgUrl: royal,
    },
    {
      name: "Jain Residency",
      address: "F-1-2, Landmark City",
      area: "Kunhadi",
      min_rent: "14000",
      imgUrl: jain,
    },
  ];

//   useEffect(() => {
//     async function getFirstFourHostels() {
//       try {
//         const responce = await axios.get(
//           `${process.env.REACT_APP_REALTIME_DB_URL}/hostels.json`
//           );
          
//           responce.data.forEach(() => {
              
//           })
//        
//       } catch (error) {
//         console.log("Error", error.message);
//       }
//     }
//     getFirstFourHostels();
    //   }, []);
    
  return (
    <div className="home-container">
      <div
        className="main"
        style={{
          backgroundImage: `linear-gradient(rgba(16,29,44,0.65),rgba(16,27,44,0.65)),url(${bg})`,
        }}
      >
        <div>
          <h1>
            Find A <span>Perfect Hostel</span> To Stay Peacefully
          </h1>
          <p>
            We provide high quality hostel and Flats in Kota, Rajasthan, and in
            Delhi/NCR so that you can focus on your goals.
          </p>
          <div>
            <button
              onClick={() => {
                navigate("/hostels");
              }}
            >
              Explore hostels
            </button>
          </div>
        </div>
      </div>
      <div className="mid-section">
        <div>
          <h1>
            #1 Place To Find A <span>Home away from Home</span>
          </h1>
          <p>
            Find a prefect place to stay in Kota, Rajasthan, and in Delhi/NCR.
            Get in touch with us and we will find you the best accomodation
            based on your budget and preferences, All for <span>FREE.</span>
          </p>
          <p className="para">
            <FaCheck />
            Prime locations
          </p>
          <p className="para">
            <FaCheck />
            Clean and Hygienic rooms
          </p>
          <p className="para">
            <FaCheck />
            Hassle free stay
          </p>
          <div>
            <button
              onClick={() => {
                navigate("/hostels");
              }}
            >
              Explore hostels
            </button>
          </div>
        </div>
      </div>
      <div className="hero-banner">
        <div>
          <img src={heroBanner} />
        </div>
      </div>
      <div className="section">
        <div className="row">
          <div>
            <h2>250+</h2>
            <p>Hostels</p>
          </div>
          <div>
            <h2>100+</h2>
            <p>PG</p>
          </div>
          <div>
            <h2>100+</h2>
            <p>Customers</p>
          </div>
        </div>
      </div>
      <div className="service">
        <div className="row1">
          <p>Our Services</p>
          <h2>Our Main Focus</h2>
        </div>
        <div className="row2">
          <div className="service-card">
            <div className="card-icon">
              <img src={service_one} alt="" />
            </div>
            <h3 className="card-title">
              <a href="#">Rent Hostel</a>
            </h3>
            <p className="card-text">
              Best hostels in prime locations nearby major institutes of Kota
              and in Delhi/NCR with all facilities.
            </p>
            <div className="service-btn">
              <a href="#">Find A Hostel</a>
            </div>
          </div>
          <div className="service-card">
            <div className="card-icon">
              <img src={service_two} alt="" />
            </div>
            <h3 className="card-title">
              <a href="#">Rent PG</a>
            </h3>
            <p className="card-text">
              We have good quality PG nearby all major institutes in Kota.
            </p>
            <div className="service-btn">
              <a href="#">Find PG</a>
            </div>
          </div>
          <div className="service-card">
            <div className="card-icon">
              <img src={service_three} alt="" />
            </div>
            <h3 className="card-title">
              <a href="#">List your property</a>
            </h3>
            <p className="card-text">
              Rent your property with Room Uncle and find access to best tenants
              and earn.
            </p>
            <div className="service-btn">
              <a href="#">List your property</a>
            </div>
          </div>
        </div>
      </div>

      <div className="service">
        <div className="row1">
          <h2>How to book?</h2>
        </div>
        <div className="row2">
          <div className="service-card">
            <div className="card-icon">
              <img src={service_one} alt="" />
            </div>
            <h3 className="card-title">
              <a href="#">Search</a>
            </h3>
            <p className="card-text">
              Search for best hostel in kota and in Delhi/NCR .
            </p>
            <div className="service-btn">
              <a href="#">Explore Hostel</a>
            </div>
          </div>
          <div className="service-card">
            <div className="card-icon">
              <img src={service_two} alt="" />
            </div>
            <h3 className="card-title">
              <a href="#">Select</a>
            </h3>
            <p className="card-text">Find hostel that best suits your needs.</p>
            <div className="service-btn">
              <a href="#">Find Hostel</a>
            </div>
          </div>
          <div className="service-card">
            <div className="card-icon">
              <img src={service_three} alt="" />
            </div>
            <h3 className="card-title">
              <a href="#">Book Hostel</a>
            </h3>
            <p className="card-text">Book with Room Uncle and check-in.</p>
            <div className="service-btn">
              <a href="#">List your property</a>
            </div>
          </div>
        </div>
      </div>
      <div className="home-hostels">
        <div className="row">
          <p>Featured Hostel</p>
          <h2>Our Flagship Hostels</h2>
        </div>

        {hostels.map((item) => (
          <div className="hostel-card-wrapper">
            <div className="hostel-card" id="hostel-card">
              {/* {item.isPremium == true && <div className="premium">Premium</div>} */}
              <div className="hostel-image">
                <img src={item.imgUrl} />
              </div>
              <div className="hostel-details">
                <div className="hostel-address-name">
                  <div className="hostel-name">{item.name}</div>
                  <div className="hostel-address">
                    <span>{item.address} </span>
                  </div>
                </div>
                <div className="hostel-price">₹ {item.min_rent}</div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="footer">
        <div className="social-media-icons">
          <div>
            <FaFacebookF />
          </div>
          <div>
            <IoLogoInstagram />
          </div>
          <div>
            <IoLogoLinkedin />
          </div>
        </div>
        <div className="info">
          <div>
            <div>
              <h4>ROOM UNCLE</h4>
              <p>
                Room Uncle provide high quality hostel, PG and Flats in Kota,
                Rajasthan, and in Delhi/NCR so that you can focus on your goals.
              </p>
            </div>
            <div>
              <h4>PROPERTIES</h4>
              <p>
                <a href="#">Hostel</a>
              </p>
              <p>
                <a href="#">PG</a>
              </p>
              <p>
                <a href="#">Flats</a>
              </p>
            </div>
            <div>
              <h4>USEFUL LINKS</h4>
              <p>
                <a href="#">Terms & Conditions</a>
              </p>
              <p>
                <a href="#">Privacy Policy</a>
              </p>
              <p>
                <a href="#">Cancellation Policy</a>
              </p>
            </div>

            <div id="contact">
              <h4>CONTACT</h4>
              <p>
                <AiFillHome />
                <span> Kota, Rajasthan</span>
              </p>
              <p>
                <MdEmail />
                <span>contact@roomuncle.com</span>
              </p>
              <p>
                <FaPhoneAlt />
                <span>8952911060</span>
              </p>
            </div>
            <div id="copyright">
              <span> &#169;&nbsp;</span>
              <a href="#">Roomuncle.com ,&nbsp; </a>
              <span>All Right Reserved</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
