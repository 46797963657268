import React from 'react'
import "./loader.css"
function Loader() {
  return (
    <div className="loadingSpinnerContainer">
      <div className="loadingSpinner"></div>
    </div>
  );
}

export default Loader