import { configureStore } from "@reduxjs/toolkit";

import { authSlice } from "./slices/authSlice";
import { hostelSlice } from "./slices/hostelSlice";
export const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    hostel: hostelSlice.reducer,
  },
});
