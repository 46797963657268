import React, { useEffect, useState } from "react";
import "./profile.css";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../components/loader/Loader";

function Profile() {
  const userLoggedIn = JSON.parse(
    localStorage.getItem("room-uncle-login-user")
  );
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (!userLoggedIn) {
  //     navigate("/login");
  //   }
  // }, []);

  return (
    <>
        <div className="profileCont">
          <div className="img-cont">
            {userLoggedIn.photoURL ? (
              <img
                src={userLoggedIn.photoURL}
                className="user-profile-pic"
                alt="profile-pic"
              />
            ) : (
              <img src="https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o=" />
            )}
          </div>
          <div className="about-user">
            <div className="name">{userLoggedIn.displayName}</div>
            <div>{userLoggedIn.email}</div>
          </div>
          <div className="profile-hostels">
            <button>
              <Link to={"/profile/my-hostels"}>My hostels</Link>
            </button>
          </div>
        </div>
    </>
  );
}

export default Profile;
