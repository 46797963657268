import React, { useState, useRef } from "react";
import "./signupWithPhone.css";
import { app } from "../../firebase";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { current } from "@reduxjs/toolkit";

function SignupWithPhone() {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [otpModal, setOtpModal] = useState(false);
  const [enteredOtp, setEnteredOtp] = useState(new Array(6).fill(""));
  const [confirmationResult, setConfirmationResult] = useState(null);
  console.log("otpModal", otpModal);
  const auth = getAuth(app);
  const sendOtp = async (e) => {
    e.preventDefault();
    console.log("sendOtp");
    console.log(phone);
    const completePhoneNumber = `+91${phone}`;
    const captcha = new RecaptchaVerifier(auth, "recaptcha-container", {
      size: "invisible",
    });
    console.log(captcha);
    try {
      const result = await signInWithPhoneNumber(
        auth,
        completePhoneNumber,
        captcha
      );
      console.log("confirmationResult", result);
      setConfirmationResult(result);
      setOtpModal(true);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handlePhoneNumber = (e) => {
    console.log("number", e.target.value);
    if (Number(e.target.value)) {
      setPhone(e.target.value);
    } else {
      setPhone("");
    }
  };
  console.log("enteredOtp", enteredOtp);
  const handleChange = (digit, index) => {
    const newArr = [...enteredOtp];
    newArr[index] = digit;
    setEnteredOtp(newArr);
  };
  const verifyOtp = async () => {
    console.log("verifyOtp");
    const otp = Number(enteredOtp.join(""));
    const result = await confirmationResult.confirm(otp);
      console.log("result", result.user);
      console.log(result.user.uid);
    if (result) {
      localStorage.setItem("room-uncle-login-user", JSON.stringify(result.user));
      navigate("/");
    }
  };

  return (
    <>
      {otpModal ? (
        <div className="otp-box-container">
          <h3>
            {" "}
            <div onClick={() => setOtpModal((flag) => !flag)}>
              <IoIosArrowRoundBack />
            </div>
            OTP Verification
          </h3>
          <div className="notification">
            <div>We have sent a verification code to</div>
            <div>
              <span>+91-</span>{phone}
            </div>
          </div>
          <div className="otp-number-cont">
            {enteredOtp.map((digit, index) => (
              <input
                type="text"
                key={index}
                maxLength={1}
                value={digit}
                onChange={(e) => handleChange(e.target.value, index)}
              />
            ))}
          </div>
          <div className="verifyOtp">
            <button onClick={verifyOtp}>VERIFY OTP</button>
          </div>
          <div className="resend">Resend Code</div>
        </div>
      ) : (
        <div className="phone-login-form">
          <form onSubmit={sendOtp}>
            <div className="back" onClick={() => navigate("/signup")}>
              <IoIosArrowRoundBack />
            </div>
            <div className="mobile-login">
              <span>+91</span>
              <input
                value={phone}
                type="text"
                placeholder="Enter mobile number"
                onChange={handlePhoneNumber}
              />
            </div>
            <div id="recaptcha-container"></div>
            <div className="mobile-login-button">
              <button
                type="submit"
                disabled={phone.length !== 10}
                style={{
                  backgroundColor: phone.length !== 10 ? "gray" : "#78472b",
                }}
              >
                SEND OTP
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default SignupWithPhone;
