import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./userSpecificHostel.css";
import { Link } from "react-router-dom";
import { getMyHostels } from "../../store/slices/hostelSlice";
import { useDispatch, useSelector } from "react-redux";
import { app } from "../../firebase";
import { getStorage, ref, deleteObject } from "firebase/storage";

function UserSpecificHostels() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storage = getStorage(app);
  console.log("storage", storage);
  const { myHostels, loading, error } = useSelector((state) => state.hostel);
  const userLoggedIn = JSON.parse(
    localStorage.getItem("room-uncle-login-user")
  );

  useEffect(() => {
    dispatch(getMyHostels(userLoggedIn.uid));
  }, []);

  const deleteHostel = async (hostel) => {
    console.log("hostel", hostel);

    // const desertRef = ref(
    //   storage,
    //   `/uploads/${hostel.hostelName.replaceAll(" ", "_")}`
    // );
    // deleteObject(desertRef)
    //   .then((res) => {
    //     // File deleted successfully
    //     console.log("res", res);
    //   })
    //   .catch((error) => {
    //     // Uh-oh, an error occurred!
    //   });

    // const res = await axios.delete(
    //   `${process.env.REACT_APP_REALTIME_DB_URL}/hostels/${hostelId}.json`
    // );
    // if (res) {
    //   // getHostels()
    //   dispatch({ type: "delete-hostel", payload: hostelId });
    // }
  };

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <div className="hostel-card-wrapper">
          {myHostels.length > 0 ? (
            myHostels.map((item, index) => (
              <div className="hostel-card" id="profile-hostel-card">
                <div
                  className="hostel-image"
                  onClick={() => navigate(`/hostel-details/${item._id}`)}
                >
                  <img src={item.uris && item.uris[0]} />
                </div>
                <div className="hostel-details">
                  <div className="hostel-address-name">
                    <div className="hostel-name">{item.hostelName}</div>
                    <div className="hostel-address">
                      <span>{item.location.address} </span>
                      <span>{item.location.area}</span>
                    </div>
                  </div>
                  <div className="hostel-price">₹ {item.rent.minRent}</div>
                </div>
                <div className="update-delete-hostel">
                  <button>
                    <Link to={`/update-hostel/${item.id}`}>Update</Link>
                  </button>
                  <button onClick={() => deleteHostel(item)}>Delete</button>
                </div>
              </div>
            ))
          ) : (
            <>
              <h3>No hostel found</h3>
              <div style={{ color: "green" }}>
                <Link to={"/add-hostel"}>Add hostel</Link>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}

export default UserSpecificHostels;
