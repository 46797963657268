import React, { useState, useEffect } from "react";
import axios from "axios";
import "./signup.css";
import logo from "../../assets/logo.svg";
import { IoEyeOutline } from "react-icons/io5";
import { FcGoogle } from "react-icons/fc";
import { Link, useNavigate } from "react-router-dom";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { createUser, signinUser } from "../../store/slices/authSlice";
import { signupWithGoogle } from "../../store/slices/authSlice";
import { app } from "../../firebase";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";

function Signup() {
  const navigate = useNavigate();
  const auth = getAuth(app);
  let { loading, error } = useSelector((state) => state.auth);
  const [otpModal, setOtpModal] = useState(true);

  const userLoggedIn = JSON.parse(
    localStorage.getItem("room-uncle-login-user")
  );
  const dispatch = useDispatch();

  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setUserDetails({
      ...userDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleGoogleSignup = async () => {
    dispatch(signupWithGoogle()).then((result) => {
      if (result.payload) {
        localStorage.setItem(
          "room-uncle-login-user",
          JSON.stringify(result.payload)
        );
        navigate("/");
      }
    });
  };

  const submitForm = async (e) => {
    e.preventDefault();
    // dispatch(createUser(userDetails.email, userDetails.password));
    // dispatch(createUser(userDetails)).then((result) => {
    //   if (result.payload) {
    //     localStorage.setItem(
    //       "room-uncle-login-user",
    //       JSON.stringify(result.payload)
    //     );
    //     navigate("/");
    //   }
    // });

    dispatch(signinUser(userDetails)).then((result) => {
      if (result.payload) {
        localStorage.setItem(
          "room-uncle-login-user",
          JSON.stringify(result.payload)
        );
        navigate("/");
      }
    });
  };

  useEffect(() => {
    if (userLoggedIn) {
      navigate("/");
    }
  }, []);

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error.message}</div>
      ) : (
        <>
          <div className="signupCont">
            <div>
              <div className="imageCont">
                <img src={logo} alt="logo" />
                <h2>Room Uncle</h2>
              </div>
              {/* <div className="login-signup-text">Log in or Sign up</div> */}
              <form className="form" onSubmit={submitForm}>
                {/* <div className="textCont">
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  onChange={handleChange}
                  value={userDetails.name}
                />
              </div> */}

                <div className="emailCont">
                  <input
                    type="email"
                    placeholder="Enter Email"
                    name="email"
                    onChange={handleChange}
                    value={userDetails.email}
                  />
                </div>

                <div className="passwordCont">
                  <input
                    type="password"
                    placeholder="Enter Password"
                    name="password"
                    onChange={handleChange}
                    value={userDetails.password}
                  />
                  <IoEyeOutline className="eye" />
                </div>

                {/* <div className="fileCont">
                <input type="file" id="file" />
                <label htmlFor="file">
                  {" "}
                  <FaCloudUploadAlt />
                  <span>UPLOAD PROFILE IMAGE</span>
                </label>
              </div> */}

                <div className="btnCont">
                  <button type="submit">Login</button>
                </div>
              </form>

              <div
                className="phone-login"
                onClick={() => navigate("/signup/phone")}
              >
                Login with Phone
              </div>
              <div className="or">
                <div className="one"></div>
                <div className="two">OR</div>
                <div className="three"></div>
              </div>

              <div className="GoogleIconCont">
                <FcGoogle className="g-icon" />
                <div onClick={handleGoogleSignup}>Sign Up with Google</div>
              </div>
              {/* <div className="hr"></div>
            <div className="navCont">
              <div className="one">Already have an account?</div>
              <div className="two">
                <Link to={"/login"}>Login</Link>
              </div>
            </div> */}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Signup;
