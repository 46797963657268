import React, { useRef, useState } from "react";
import { FaUser } from "react-icons/fa6";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";
import { CgProfile } from "react-icons/cg";
import { CiGrid41 } from "react-icons/ci";
import { FaUserEdit } from "react-icons/fa";
import { IoLogOutOutline } from "react-icons/io5";
import { FaBars } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";
import { UseSelector, useDispatch, useSelector } from "react-redux";

import "./header.css";

function Header() {
  console.log("header");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { loading, error } = useSelector((state) => state.auth);
  const userLoggedIn = JSON.parse(
    localStorage.getItem("room-uncle-login-user")
  );
  console.log("userLoggedIn", userLoggedIn);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const imgRef = useRef();
  const profileBoxRef = useRef();
  const [toggleMenuIcon, setToggleMenuIcon] = useState(false);
  window.addEventListener("resize", () => {
    console.log(window.innerWidth);
    if (window.innerWidth > 500) {
      setToggleMenuIcon(false);
    }
  });

  const profileMenu = [
    ["CgProfile", "Profile"],
    ["", "Add Hostel"],
    ["CiGrid41", "My Hostels"],
    ["FaUserEdit", "Update Profile"],
    ["IoLogOutOutline", "Logout"],
  ];

  window.addEventListener("click", (e) => {
    if (e.target !== profileBoxRef.current && e.target !== imgRef.current) {
      setOpenProfileMenu(false);
    }
  });

  const handleClick = (val) => {
    setOpenProfileMenu(false);
    if (val == "Profile") {
      navigate("/profile");
    } else if (val == "My Hostels") {
      navigate("/profile/my-hostels");
    } else if (val == "Logout") {
      localStorage.removeItem("room-uncle-login-user");
      navigate("/");
    } else if (val == "Add Hostel") {
      navigate("/add-hostel");
    }
  };

  return (
    <>
      <div className="headerCont">
        <div className="dashboard">
          <div className="logo">
            <img src={logo} alt="logo" onClick={() => navigate("/")} />
            <span>Room Uncle</span>
          </div>

          <>
            {userLoggedIn && (
              <>
                {/* <div className="add-hostel">
                  <Link to={"/add-hostel"}>Add Hostel</Link>
                </div> */}

                <div className="header-profile">
                  <img
                    onClick={() => setOpenProfileMenu(!openProfileMenu)}
                    ref={imgRef}
                    src="https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o="
                  />

                  {openProfileMenu && (
                    <div ref={profileBoxRef}>
                      {profileMenu.map(([icon, val]) => (
                        <div onClick={() => handleClick(val)}>
                          <div>{val}</div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </>
            )}
          </>
        </div>

        {!userLoggedIn && (
          <div className="login-signup-button">
            <Link to={"/signup"}>
              <button>
                {" "}
                <FaUser className="btn-icon" />
                SignUp/Login
              </button>
            </Link>
          </div>
        )}

        <>
          {userLoggedIn && (
            <>
              {toggleMenuIcon ? (
                <div
                  className="menu-icon"
                  onClick={() => setToggleMenuIcon(!toggleMenuIcon)}
                >
                  <FaTimes />
                </div>
              ) : (
                <div
                  className="menu-icon"
                  onClick={() => setToggleMenuIcon(!toggleMenuIcon)}
                >
                  <FaBars />
                </div>
              )}
            </>
          )}
        </>
      </div>

      <div
        className={`responsive-menu ${
          toggleMenuIcon ? "show-menu" : "hidden-menu"
        }`}
      >
        <div
          onClick={() => {
            navigate("/add-hostel");
            setToggleMenuIcon(false);
          }}
        >
          Add Hostel
        </div>
        <div
          onClick={() => {
            navigate("/profile");
            setToggleMenuIcon(false);
          }}
        >
          Profile
        </div>
        <div
          onClick={() => {
            navigate("/profile/my-hostels");
            setToggleMenuIcon(false);
          }}
        >
          My Hostels
        </div>
        <div>Update Profile</div>
        <div
          onClick={() => {
            localStorage.removeItem("room-uncle-login-user");
            setToggleMenuIcon(false);
            navigate("/signup");
          }}
        >
          Logout
        </div>
      </div>
    </>
  );
}

export default Header;
