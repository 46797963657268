import React, { useState, useEffect } from "react";
import "./addHostel.css";
import axios from "axios";
import Loader from "../../components/loader/Loader";
import { useNavigate } from "react-router-dom";
import { app } from "../../firebase";
import {
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

function AddHostel() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const userLoggedIn = JSON.parse(
    localStorage.getItem("room-uncle-login-user")
  );
  let states = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
  ];
  const storage = getStorage(app);

  const [files, setFiles] = useState(null);
  const [state, setState] = useState("select state");
  const [partnerCode, setPartnerCode] = useState("");
  const [isPremium, setIsPremium] = useState(false);
  const [hostelName, setHostelName] = useState("");
  const [gender, setGender] = useState("");
  const [minRent, setMinRent] = useState("");
  const [maxRent, setMaxRent] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [ownerContact, setOwnerContact] = useState();
  const [managerName, setManagerName] = useState("");
  const [managerContact, setManagerContact] = useState();
  const [emailAddress, setEmailAddress] = useState();
  const [address, setAddress] = useState("");
  const [area, setArea] = useState("");
  const [landmark, setLandmark] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [isSingleRoomAvailable, setIsSingleRoomAvailable] = useState(false);
  const [isDoubleRoomAvailable, setIsDoubleRoomAvailable] = useState(false);
  const [housekeepingAvailable, setHousekeepingAvailable] = useState(false);
  const [wardenAvailable, setWardenAvailable] = useState(false);
  const [laundryAvailable, setLaundryAvailable] = useState(false);
  const [foodAvailable, setFoodAvailable] = useState(false);
  const [wifiAvailable, setWifiAvailable] = useState(false);
  const [acAvailable, setAcAvailable] = useState(false);
  const [cctvAvailable, setCctvAvailable] = useState(false);
  const [geyserAvailable, setGeyserAvailable] = useState(false);
  const [waterPurifierAvailable, setWaterPurifierAvailable] = useState(false);
  const [powerBackupAvailable, setPowerBackupAvailable] = useState(false);
  const [liftAvailable, setLiftAvailable] = useState(false);
  const [securityDeposit, setSecurityDeposit] = useState(false);
  //  const [securityDepositDetails, setSecurityDepositDetails] = useState();
  const [oppositeGenderAllowed, setOppositeGenderAllowed] = useState(false);
  const [guardianStayAllowed, setGuardianStayAllowed] = useState(false);
  const [nonVegAllowed, setNonVegAllowed] = useState(false);

  // useEffect(() => {
  //   if (!userLoggedIn) {
  //     navigate("/");
  //   }
  // }, []);
  const addHostelData = async ({ hostelData, images, userLoggedIn }) => {
    const storeImage = (image) => {
      return new Promise((resolve, reject) => {
        const storageRef = ref(
          storage,
          `/uploads/${hostelData.hostelName.replaceAll(
            " ",
            "_"
          )}/${Date.now()}-${image.name}`
        );
        const uploadTask = uploadBytesResumable(storageRef, image);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
              default:
                break;
            }
          },
          (error) => {
            reject(error);
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    };

    const imgUrls = await Promise.all(
      [...images].map((image) => storeImage(image))
    ).catch((error) => {
      // setLoading(false);
      // toast.error("Images not uploaded");
      console.log(error);
      return;
    });

    console.log("imgUrls", imgUrls);

    hostelData.uris = imgUrls;

    // hostelData.userId = userLoggedIn.uid;
    hostelData.uid = userLoggedIn.uid;

    // https://room-uncle-66908-default-rtdb.firebaseio.com/hostels.json

    //  await axios.post(
    //   `http://localhost:8000/add-hostel`,
    //   hostelData
    // );

      const res = await axios.post(
        `${process.env.REACT_APP_REALTIME_DB_URL}/hostels.json`,
        hostelData
      );
  };

  // useEffect(() => {
  //   if (!userLoggedIn) {
  //     navigate("/login");
  //   }
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const hostelData = {
      hostelName: hostelName,
      isPremium: isPremium,
      gender: gender,

      location: {
        address: address,
        area: area,
        landmark: landmark,
        pincode: pincode,
        city: city,
        state: state,
      },
      rent: {
        minRent: minRent,
        maxRent: maxRent,
        securityDeposit: securityDeposit,
      },
      ownerName: ownerName,
      ownerContact: ownerContact,
      emailAddress: emailAddress,
      managerName: managerName,
      managerContact: managerContact,
      additionalInfo: additionalInfo,
      occupancyType: {
        isSingleRoomAvailable: isSingleRoomAvailable,
        isDoubleRoomAvailable: isDoubleRoomAvailable,
      },
      services: {
        housekeepingAvailable: housekeepingAvailable,
        wardenAvailable: wardenAvailable,
        laundryAvailable: laundryAvailable,
        foodAvailable: foodAvailable,
      },
      amenities: {
        wifiAvailable: wifiAvailable,
        acAvailable: acAvailable,
        cctvAvailable: cctvAvailable,
        geyserAvailable: geyserAvailable,
        waterPurifierAvailable: waterPurifierAvailable,
        powerBackupAvailable: powerBackupAvailable,
        liftAvailable: liftAvailable,
      },
      rules: {
        oppositeGenderAllowed: oppositeGenderAllowed,
        guardianStayAllowed: guardianStayAllowed,
        nonVegAllowed: nonVegAllowed,
      },
    };
    setLoading(true);
    addHostelData({ hostelData, images: files, userLoggedIn }).then((res) => {
      console.log("res", res);
      setLoading(false);
      navigate("/hostels");
    });
  };

  return (
    <>
      <div className="form-cont">
        <form className="form-wrapper" onSubmit={handleSubmit}>
          <div className="hostel-name text">
            <label>
              <span> Hostel Name:</span>
              <input
                type="text"
                placeholder="Enter hostel name"
                value={hostelName}
                onChange={(e) => setHostelName(e.target.value)}
              />
            </label>
          </div>

          <div className="hostel-address text">
            <label>
              <span> Hostel Address:</span>
              <input
                type="text"
                placeholder="Enter hostel address"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </label>
          </div>
          <div className="area text">
            <label>
              <span> Area:</span>
              <input
                type="text"
                placeholder="area"
                value={area}
                onChange={(e) => setArea(e.target.value)}
              />
            </label>
          </div>

          <div className="landmark text">
            <label>
              <span> Landmark:</span>
              <input
                type="text"
                placeholder="landmark"
                value={landmark}
                onChange={(e) => setLandmark(e.target.value)}
              />
            </label>
          </div>

          <div className="pincode text">
            <label>
              <span> Pincode:</span>
              <input
                type="text"
                placeholder="pincode"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
              />
            </label>
          </div>

          <div className="city text">
            <label>
              <span>City:</span>
              <input
                type="text"
                placeholder="city"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </label>
          </div>

          <div className="states text">
            <label>
              <span>State:</span>
              <select value={state} onChange={(e) => setState(e.target.value)}>
                <option value="select state">select state</option>
                {states.map((state) => (
                  <option value={state}>{state}</option>
                ))}
              </select>
            </label>
          </div>

          <div className="owner-name text">
            <label>
              <span> Owner Name:</span>
              <input
                type="text"
                placeholder="9999999999"
                value={ownerName}
                onChange={(e) => setOwnerName(e.target.value)}
              />
            </label>
          </div>

          <div className="owner-number text">
            <label>
              <span> Owner Number:</span>
              <input
                type="text"
                placeholder="9999999999"
                value={ownerContact}
                onChange={(e) => setOwnerContact(e.target.value)}
              />
            </label>
          </div>

          {/* <div className="mobile-number text">
              <label>
                <span> Mobile number:</span>
                <input
                  type="text"
                  placeholder="9999999999"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
              </label>
            </div> */}

          {/* <div className="alternate-mobile-number text">
              <label>
                <span> Alternate Mobile number:</span>
                <input
                  type="text"
                  placeholder="9999999999"
                  value={altMobileNumber}
                  onChange={(e) => setAltMobileNumber(e.target.value)}
                />
              </label>
            </div> */}

          <div className="manager-name text">
            <label>
              <span> Manager Name:</span>
              <input
                type="text"
                placeholder="Enter manager name"
                value={managerName}
                onChange={(e) => setManagerName(e.target.value)}
              />
            </label>
          </div>

          <div className="manager-number text">
            <label>
              <span> Manager Number:</span>
              <input
                type="text"
                placeholder="9999999999"
                value={managerContact}
                onChange={(e) => setManagerContact(e.target.value)}
              />
            </label>
          </div>

          <div className="available-for">Available For</div>
          <div id="available-for">
            {/* <label>
            <input
              type="radio"
              value={"Unisex"}
              onChange={(e) => setGender(e.target.value)}
              checked={gender === "Unisex" ? true : false}
            />
            <span> Unisex</span>
          </label> */}
            <label>
              <input
                type="radio"
                value={"Male"}
                onChange={(e) => setGender(e.target.value)}
                checked={gender === "Male" ? true : false}
              />
              <span> Male</span>
            </label>
            <label>
              <input
                type="radio"
                value={"Female"}
                onChange={(e) => setGender(e.target.value)}
                checked={gender === "Female" ? true : false}
              />
              <span> Female</span>
            </label>
          </div>

          {/* <div className="hostel-type">Hostel Type</div>
            <div id="hostel-type">
              <label>
                <input
                  type="radio"
                  value={"Premium"}
                  onChange={(e) => setHostelType(e.target.value)}
                  checked={hostelType === "Premium" ? true : false}
                />
                <span>Premium </span>
              </label>

              <label>
                <input
                  type="radio"
                  value={"Economic"}
                  onChange={(e) => setHostelType(e.target.value)}
                  checked={hostelType === "Economic" ? true : false}
                />
                <span>Economic </span>
              </label>
            </div> */}
          <div className="minimum-rent text">
            <label>
              <span> Minimum rent (Rs):</span>
              <input
                type="text"
                placeholder="5000"
                value={minRent}
                onChange={(e) => setMinRent(e.target.value)}
              />
            </label>
          </div>

          <div className="maximum-rent text">
            <label>
              <span> Maximum rent (Rs):</span>
              <input
                type="text"
                placeholder="5000"
                value={maxRent}
                onChange={(e) => setMaxRent(e.target.value)}
              />
            </label>
          </div>

          <div className="security-deposit">
            <label>
              <input
                type="checkbox"
                onChange={() => setSecurityDeposit(!securityDeposit)}
                checked={securityDeposit ? true : false}
              />
              <span> Security Deposit</span>
            </label>
          </div>

          <div className="isPremium">
            <label>
              <input
                type="checkbox"
                onChange={() => setIsPremium(!isPremium)}
                checked={isPremium ? true : false}
              />
              <span>Premium</span>
            </label>
          </div>

          <div className="email-address text">
            <label>
              <span> Email address:</span>
              <input
                type="text"
                placeholder="abc@gmail.com"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
            </label>
          </div>

          <div className="occupancy-type">Occupancy Type:</div>
          <div id="occupancy-type">
            <label>
              <input
                type="checkbox"
                value={"Single"}
                onChange={() =>
                  setIsSingleRoomAvailable(!isSingleRoomAvailable)
                }
                checked={isSingleRoomAvailable ? true : false}
              />
              <span> Single</span>
            </label>
            <label>
              <input
                type="checkbox"
                value={"Double"}
                onChange={() =>
                  setIsDoubleRoomAvailable(!isDoubleRoomAvailable)
                }
                checked={isDoubleRoomAvailable ? true : false}
              />
              <span> Double</span>
            </label>
          </div>

          <div className="available-services">Available Services:</div>
          <div id="available-services">
            <label>
              <input
                type="checkbox"
                value={"Housekeeping"}
                onChange={() =>
                  setHousekeepingAvailable(!housekeepingAvailable)
                }
                checked={housekeepingAvailable ? true : false}
              />
              <span> Housekeeping</span>
            </label>
            <label>
              <input
                type="checkbox"
                value={"Warden"}
                onChange={() => setWardenAvailable(!wardenAvailable)}
                checked={wardenAvailable ? true : false}
              />
              <span>Warden</span>
            </label>
            <label>
              <input
                type="checkbox"
                value={"Laundry"}
                onChange={() => setLaundryAvailable(!laundryAvailable)}
                checked={laundryAvailable ? true : false}
              />
              <span> Laundry</span>
            </label>

            <label>
              <input
                type="checkbox"
                value={"Food"}
                onChange={() => setFoodAvailable(!foodAvailable)}
                checked={foodAvailable ? true : false}
              />
              <span> Food</span>
            </label>
          </div>

          <div className="available-amenities">Available Amenities:</div>
          <div id="available-amenities">
            <label>
              <input
                type="checkbox"
                value={"AC"}
                onChange={() => setAcAvailable(!acAvailable)}
                checked={acAvailable ? true : false}
              />
              <span> AC</span>
            </label>
            <label>
              <input
                type="checkbox"
                value={"Power Backup"}
                onChange={() => setPowerBackupAvailable(!powerBackupAvailable)}
                checked={powerBackupAvailable ? true : false}
              />
              <span> Power Backup</span>
            </label>
            <label>
              <input
                type="checkbox"
                value={"Water Purifier"}
                onChange={() =>
                  setWaterPurifierAvailable(!waterPurifierAvailable)
                }
                checked={waterPurifierAvailable ? true : false}
              />
              <span> Water Purifier</span>
            </label>

            <label>
              <input
                type="checkbox"
                value={"Wifi"}
                onChange={() => setWifiAvailable(!wifiAvailable)}
                checked={wifiAvailable ? true : false}
              />
              <span> Wifi</span>
            </label>

            <label>
              <input
                type="checkbox"
                value={"Geyser"}
                onChange={() => setGeyserAvailable(!geyserAvailable)}
                checked={geyserAvailable ? true : false}
              />
              <span> Geyser</span>
            </label>

            <label>
              <input
                type="checkbox"
                value={"CCTV"}
                onChange={() => setCctvAvailable(!cctvAvailable)}
                checked={cctvAvailable ? true : false}
              />
              <span> CCTV</span>
            </label>
            <label>
              <input
                type="checkbox"
                value={"Lift"}
                onChange={() => setLiftAvailable(!liftAvailable)}
                checked={liftAvailable ? true : false}
              />
              <span> Lift (Elevator)</span>
            </label>
          </div>

          <div className="additional-info">Additional Info:</div>

          <div id="additional-info">
            <label>
              <input
                type="checkbox"
                value={"Non veg allowed"}
                onChange={() => setNonVegAllowed(!nonVegAllowed)}
                checked={nonVegAllowed ? true : false}
              />
              <span> Non veg allowed</span>
            </label>

            <label>
              <input
                type="checkbox"
                value={"opposite gender allowed"}
                onChange={() =>
                  setOppositeGenderAllowed(!oppositeGenderAllowed)
                }
                checked={oppositeGenderAllowed ? true : false}
              />
              <span> Opposite gender allowed</span>
            </label>

            <label>
              <input
                type="checkbox"
                value={"guardian stay allowed"}
                onChange={() => setGuardianStayAllowed(!guardianStayAllowed)}
                checked={guardianStayAllowed ? true : false}
              />
              <span> Guardian stay allowed</span>
            </label>
          </div>

          <div className="additional-note">Additional Note(Optional):</div>
          <div id="additional-note">
            <textarea
              placeholder="some text"
              value={additionalInfo}
              onChange={(e) => setAdditionalInfo(e.target.value)}
            ></textarea>
          </div>

          <div className="select-image">
            <label>
              <span>Select Hostel Images</span>
              <input
                type="file"
                onChange={(e) => setFiles(e.target.files)}
                name="photos"
                multiple
              />
            </label>
          </div>
          <div className="button">
            <button type="submit">save</button>
          </div>
          {loading && (
            <div>
              {" "}
              <Loader />
            </div>
          )}
        </form>
      </div>
    </>
  );
}

export default AddHostel;
