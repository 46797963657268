import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { app } from "../../firebase";
const storage = getStorage(app);

export const getHostels = createAsyncThunk("getHostels", async (pageNumber) => {
  console.log("hiiiiiiiii");
  const res = await axios.get(
    `${process.env.REACT_APP_REALTIME_DB_URL}/hostels.json`
  );
  console.log("heeee", res.data);
  return res.data;

  // const res = await axios.get(
  //   `http://localhost:8000/get-hostels/${pageNumber}`
  // );
  // console.log("res.data", res.data);
  // return res.data;

  //   try {
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_REALTIME_DB_URL}/hostels.json`
  //     );
  //     if (res) {
  //       return res.data;
  //     }
  //   } catch (error) {
  //     return error;
  //   }
});

export const getMyHostels = createAsyncThunk("getMyHostels", async (userId) => {
  // const res = await axios.get(`http://localhost:8000/get-Myhostels/${userId}`);
  // console.log("res", res.data);
  // return res.data;

  const res = await axios.get(
    `${process.env.REACT_APP_REALTIME_DB_URL}/hostels.json?orderBy="uid"&equalTo="${userId}"`
  );
  return res.data;
  //   try {
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_REALTIME_DB_URL}/hostels.json?orderBy="uid"&equalTo="${userId}"`
  //     );
  //     if (res) {
  //       return res.data;
  //     }
  //   } catch (error) {
  //     return error;
  //   }
});

export const hostelSlice = createSlice({
  name: "hostels",
  initialState: {
    loading: false,
    error: null,
    hostels: [],
    myHostels: [],
  },
  reducers: {
    // getHostel: (state, action) => {
    //   console.log("gethostel called", action.payload);
    //   state.specificHostelDetails = state.hostels.find((hostel) => {
    //     return hostel.id == action.payload;
    //   });
    // }
  },

  extraReducers: (builder) => {
    builder.addCase(getHostels.pending, (state, action) => {
      console.log("pending");
      state.loading = true;
    });
    builder.addCase(getHostels.fulfilled, (state, action) => {
      console.log("fullfilled");
      state.loading = false;
      const newHostels = [];
      console.log(action.payload);
      Object.entries(action.payload).forEach(([k, v]) => {
        newHostels.push({ id: k, ...v });
      });
      // state.hostels.push(...action.payload.hostels);
      state.hostels = newHostels;
    });
    builder.addCase(getHostels.rejected, (state, action) => {
      console.log("rejected");
      state.loading = false;
      console.log("AAA", action.payload);
      state.error = action.payload;
    });

    builder.addCase(getMyHostels.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getMyHostels.fulfilled, (state, action) => {
      console.log(action.payload);
      state.loading = false;
      const newHostels = [];
      Object.entries(action.payload).forEach(([k, v]) => {
        newHostels.push({ id: k, ...v });
      });
      state.myHostels = action.payload.hostels;
      state.myHostels = newHostels;
    });
    builder.addCase(getMyHostels.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

// export const {  } = hostelSlice.actions;
