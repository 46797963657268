import React, { useState, useEffect } from "react";
import axios from "axios";
import "./hostel-details.css";
import { useParams, useNavigate } from "react-router-dom";
import { FaMapMarkerAlt } from "react-icons/fa";
import { IoRestaurant } from "react-icons/io5";
import { FaBug } from "react-icons/fa";
import { GiWashingMachine } from "react-icons/gi";
import { IoIosMan } from "react-icons/io";
import { IoGridOutline } from "react-icons/io5";
import { IoCameraOutline } from "react-icons/io5";
import { CiWifiOn } from "react-icons/ci";
import { IoIosColorFill } from "react-icons/io";
import { IoWaterOutline } from "react-icons/io5";
import { IoBulb } from "react-icons/io5";
import { FaArrowUp } from "react-icons/fa";
import { IoArchiveOutline } from "react-icons/io5";
import { IoMaleFemaleSharp } from "react-icons/io5";
import { IoPeopleSharp } from "react-icons/io5";
import { IoFastFood } from "react-icons/io5";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { FaRegStar } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";
import Carousel from "../../components/carousel/Carousel";

function HostelDetails() {
  const params = useParams();
  const [ratingModal, setRatingModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [ratingValue, setRatingValue] = useState(0);
  const [hoverRatingValue, setHoverRatingValue] = useState(0);
  const [review, setReview] = useState("");
  const [reviewsModal, setReviewsModal] = useState(false);
  const [hostelData, setHostelData] = useState(null);
  const ratingArr = [1, 2, 3, 4, 5];
  const navigate = useNavigate();
  const userLoggedIn = JSON.parse(
    localStorage.getItem("room-uncle-login-user")
  );

  let averageHostelRating;
  if (hostelData) {
    if (hostelData.ratings) {
      averageHostelRating = hostelData.ratings.reduce(
        (accumulator, currentValue, index) => accumulator + currentValue.rating,
        0
      );
      averageHostelRating = averageHostelRating / hostelData.ratings.length;
      averageHostelRating = averageHostelRating.toFixed(2);
    }
    console.log("averageHostelRating", averageHostelRating);
  }

  const getHostel = async () => {
    try {
      setLoading(true);
      // const res = await axios.get(
      //   `http://localhost:8000/specific-hostel/${params.hostelId}`
      // );

      const res = await axios.get(
        `${process.env.REACT_APP_REALTIME_DB_URL}/hostels/${params.hostelId}.json`
      );
      setHostelData(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  useEffect(() => {
    getHostel();
  }, []);

  const submitReview = async () => {
    console.log(hostelData.ratings);
    let ratingArr = [];
    let existingRating = hostelData.ratings.find(
      (rating) => rating.userId == userLoggedIn.uid
    );
    console.log("existingRating", existingRating);
    if (existingRating) {
      let newRating = hostelData.ratings;

      newRating = newRating.filter(
        (rating) => rating.userId != userLoggedIn.uid
      );

      newRating.push({
        rating: ratingValue,
        review: review,
        userId: userLoggedIn.uid,
        user: userLoggedIn.displayName ? userLoggedIn.displayName : "Anonymous",
      });
      ratingArr = newRating;
    } else {
      let newRating = hostelData.ratings;
      newRating.push({
        rating: ratingValue,
        review: review,
        userId: userLoggedIn.uid,
        user: userLoggedIn.displayName ? userLoggedIn.displayName : "Anonymous",
      });
      ratingArr = newRating;
    }

    console.log("ratingArr", ratingArr);

    // const res = await axios.patch(
    //   `${process.env.REACT_APP_REALTIME_DB_URL}/hostels/${params.hostelId}.json`,
    //   hostelData
    // );
    const res = await axios.patch(
      `http://localhost:8000/add-rating/${params.hostelId}`,
      ratingArr
    );
    console.log(res.data.hostel);
    setHostelData(res.data.hostel);
    cancelReview();
  };

  const handleRating = () => {
    if (!userLoggedIn) {
      alert("Please login first");
      navigate("/signup");
    }
    setRatingModal(!ratingModal);
  };

  const cancelReview = () => {
    setRatingModal(false);
    setRatingValue(0);
    setHoverRatingValue(0);
    setReview("");
  };

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <>
          {hostelData && (
            <div className="specific">
              <div className="hostel-wrapper">
                <div className="contact-us">
                  <div onClick={() => navigate(`/contact/${params.hostelId}`)}>
                    <span> Contact Us</span>
                    <FaPhone />
                  </div>
                </div>

                <div className="hostel-name">
                  <h2>{hostelData.hostelName}</h2>
                  <div>
                    {hostelData.location.address}
                    {","}

                    {hostelData.location.area}
                  </div>
                  <div className="location">
                    <FaMapMarkerAlt />
                    <h3>{hostelData.location.city}</h3>
                  </div>
                </div>
                <div className="image">
                  {hostelData.uris && <Carousel images={hostelData.uris} />}
                </div>

                <div className="rating-gender-container">
                  <div className="gender">For {hostelData.gender}</div>
                  <div>
                    {!isNaN(averageHostelRating) && (
                      <div>
                        <button>
                          <span>{averageHostelRating}</span>
                          <AiFillStar />
                          <span
                            className="view-review"
                            onClick={() => setReviewsModal(true)}
                          >
                            ({hostelData.ratings.length} Review)
                          </span>
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                {hostelData.isPremium && (
                  <>
                    {hostelData.ownerName && (
                      <div className="owner">
                        <h2>
                          {" "}
                          Owner Name: <span>{hostelData.ownerName}</span>
                        </h2>
                      </div>
                    )}
                    {hostelData.ownerContact && (
                      <div className="owner">
                        <h2>
                          {" "}
                          Owner Contact: <span>{hostelData.ownerContact}</span>
                        </h2>
                      </div>
                    )}

                    {hostelData.managerName && (
                      <div className="manager">
                        <h2>
                          {" "}
                          Manager Name: <span>{hostelData.managerName}</span>
                        </h2>
                      </div>
                    )}
                  </>
                )}

                {hostelData.additionalInfo && (
                  <div className="additionalInfo">
                    <div>{hostelData.additionalInfo}</div>
                  </div>
                )}

                <div className="rentCont">
                  {hostelData.rent.minRent && (
                    <div className="min-rent">
                      {" "}
                      Min rent: ₹ {hostelData.rent.minRent}
                    </div>
                  )}
                  {hostelData.rent.maxRent && (
                    <div className="max-rent">
                      {" "}
                      Max rent: ₹ {hostelData.rent.maxRent}
                    </div>
                  )}
                  <div className="single-room">
                    Single Room :{" "}
                    {hostelData.occupancyType.isSingleRoomAvailable
                      ? "Available"
                      : "Not Available"}
                  </div>
                  <div className="double-room">
                    Double Room :{" "}
                    {hostelData.occupancyType.isDoubleRoomAvailable
                      ? "Available"
                      : "Not Available"}
                  </div>
                </div>

                <div className="common">
                  {Object.entries(hostelData.services).some(
                    ([k, val]) => val === true
                  ) && <h2>Services</h2>}
                  <div>
                    {hostelData.services.foodAvailable ? (
                      <div>
                        <div>
                          <IoRestaurant />
                        </div>
                        <div>Food</div>
                      </div>
                    ) : (
                      ""
                    )}
                    {hostelData.services.housekeepingAvailable ? (
                      <div>
                        <div>
                          <FaBug />
                        </div>
                        <div>Housekeeping</div>
                      </div>
                    ) : (
                      ""
                    )}
                    {hostelData.services.laundryAvailable ? (
                      <div>
                        <div>
                          <IoArchiveOutline />
                        </div>
                        <div>Laundry</div>
                      </div>
                    ) : (
                      ""
                    )}
                    {hostelData.services.wardenAvailable ? (
                      <div>
                        <div>
                          <IoIosMan />
                        </div>
                        <div>Warden</div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="common">
                  {Object.entries(hostelData.amenities).some(
                    ([k, val]) => val === true
                  ) && <h2>Amenities</h2>}
                  <div>
                    {hostelData.amenities.acAvailable ? (
                      <div>
                        <div>
                          <IoGridOutline />
                        </div>
                        <div>AC</div>
                      </div>
                    ) : (
                      ""
                    )}
                    {hostelData.amenities.cctvAvailable ? (
                      <div>
                        <div>
                          <IoCameraOutline />
                        </div>
                        <div>CCTV</div>
                      </div>
                    ) : (
                      ""
                    )}
                    {hostelData.amenities.geyserAvailable ? (
                      <div>
                        <div>
                          <IoIosColorFill />
                        </div>
                        <div>Geyser</div>
                      </div>
                    ) : (
                      ""
                    )}

                    {hostelData.amenities.liftAvailable ? (
                      <div>
                        <div>
                          <FaArrowUp />
                        </div>
                        <div>Lift</div>
                      </div>
                    ) : (
                      ""
                    )}

                    {hostelData.amenities.powerBackupAvailable ? (
                      <div>
                        <div>
                          <IoBulb />
                        </div>
                        <div>Power Backup</div>
                      </div>
                    ) : (
                      ""
                    )}

                    {hostelData.amenities.waterPurifierAvailable ? (
                      <div>
                        <div>
                          <IoWaterOutline />
                        </div>
                        <div>Water Purifier</div>
                      </div>
                    ) : (
                      ""
                    )}

                    {hostelData.amenities.wifiAvailable ? (
                      <div>
                        <div>
                          <CiWifiOn />
                        </div>
                        <div>Wifi</div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="common">
                  <h2>Rules</h2>
                  <div>
                    <div>
                      <div className="sub-common">
                        <div>
                          <IoMaleFemaleSharp />
                        </div>
                        <div>Opposite Gender:</div>
                      </div>
                      {hostelData.rules.oppositeGenderAllowed ? (
                        <div>Allowed</div>
                      ) : (
                        <div>Not Allowed</div>
                      )}
                    </div>

                    <div>
                      <div className="sub-common">
                        <div>
                          <IoPeopleSharp />
                        </div>
                        <div>Guardian Stay:</div>
                      </div>
                      {hostelData.rules.guardianStayAllowed ? (
                        <div>Allowed</div>
                      ) : (
                        <div>Not Allowed</div>
                      )}
                    </div>

                    <div>
                      <div className="sub-common">
                        <div>
                          <IoFastFood />
                        </div>
                        <div>Non Veg:</div>
                      </div>
                      {hostelData.rules.nonVegAllowed ? (
                        <div>Allowed</div>
                      ) : (
                        <div>Not Allowed</div>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="rate-container">
                  <button onClick={handleRating}>Rate Now</button>
                </div> */}

                {ratingModal && (
                  <div className="rating-modal">
                    <div>
                      <div>Please rate your experience with us</div>
                      {ratingValue === 0 ? (
                        <div
                          className="rating"
                          onMouseLeave={() => setHoverRatingValue(0)}
                        >
                          {ratingArr.map((val, index) => {
                            if (val <= hoverRatingValue) {
                              return (
                                <AiFillStar
                                  onMouseEnter={() => setHoverRatingValue(val)}
                                  onClick={() => setRatingValue(val)}
                                />
                              );
                            } else {
                              return (
                                <AiOutlineStar
                                  onMouseEnter={() => setHoverRatingValue(val)}
                                  onClick={() => setRatingValue(val)}
                                />
                              );
                            }
                          })}
                        </div>
                      ) : (
                        <div className="rating">
                          {ratingArr.map((val, index) => {
                            if (val <= ratingValue) {
                              return (
                                <AiFillStar
                                  onClick={() => setRatingValue(val)}
                                />
                              );
                            } else {
                              return (
                                <AiOutlineStar
                                  onClick={() => setRatingValue(val)}
                                />
                              );
                            }
                          })}
                        </div>
                      )}
                      <div className="review">
                        <textarea
                          placeholder="Add Your Review"
                          value={review}
                          onChange={(e) => setReview(e.target.value)}
                        ></textarea>
                      </div>
                      <div className="rating-button-container">
                        <button onClick={cancelReview}>Cancel</button>
                        <button onClick={submitReview}>Submit</button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {hostelData && hostelData.ratings && reviewsModal && (
                <div className="reviews-container">
                  <div>
                    <h2>Room Uncle Reviews</h2>
                    <div
                      className="cancel-reviews-modal"
                      onClick={() => setReviewsModal(false)}
                    >
                      <FaTimes />
                    </div>
                    <div className="review-card-container">
                      {hostelData.ratings.map((val) => (
                        <div className="review-card">
                          <img
                            src="https://media.istockphoto.com/id/1300845620/vector/user-icon-flat-isolated-on-white-background-user-symbol-vector-illustration.jpg?s=612x612&w=0&k=20&c=yBeyba0hUkh14_jgv1OKqIH0CCSWU_4ckRkAoy2p73o="
                            alt=""
                          />

                          <span>{val.user}</span>

                          <>
                            <div>
                              {[...Array(val.rating)].map(
                                (v, i) => i < val.rating && <AiFillStar />
                              )}
                            </div>
                            <span>{val.review}</span>
                          </>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}

export default HostelDetails;
