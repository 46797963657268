import React, { useEffect, useRef, useState } from "react";
import "./hostel.css";
import { useNavigate } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { getHostels } from "../../store/slices/hostelSlice";
import { useDispatch, useSelector } from "react-redux";
import { AiFillStar } from "react-icons/ai";

function Hostel() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { error, loading, hostels } = useSelector((state) => state.hostel);
  // const [hostels, setHostels] = useState([]);
  console.log("hostels", hostels);
  const [genderValue, setGenderValue] = useState("All");
  const [pageNumber, setPageNumber] = useState(1);
  console.log("limit", pageNumber);
  const genders = ["All", "For Boys", "For Girls"];
  const [openCityMenu, setOpenCityMenu] = useState(false);
  const [openLocalityMenu, setOpenLocalityMenu] = useState(false);
  const [openGenderMenu, setOpenGenderMenu] = useState(false);
  const [currentCity, setCurrentCity] = useState("Kota");
  const [currentLocation, setCurrentLocation] = useState("");
  const citiesArr = ["Kota", "Delhi"];
  const cityRef = useRef();
  const cityMenuRef = useRef();
  const localityRef = useRef();
  const localityMenuRef = useRef();


  const kotaArea = [
    // { Kota: "Anywhere in Kota" },
    { Kunhadi: "Landmark City (Kunhadi)" },
    { Talwandi: "Talwandi" },
    { "Jawahar Nagar": "Jawahar Nagar" },
    { "Coral Park": "Coral Park (naya nohra)" },
    { "Electronic Complex": "Electronic Complex" },
    { "Rajeev Gandhi Nagar": "Rajeev Gandhi Nagar" },
    { "Mahaveer Nagar": "Mahaveer Nagar" },
    { IPIA: "Near by Unacademy, Physics Walalh (IPIA)" },
  ];
  const delhiArea = [
    { "Laxmi Nagar": "Laxmi Nagar" },
    { "Nirman Vihar": "Nirman Vihar" },
    { "New Ashok Nagar": "New Ashok Nagar" },
    { "Mayur Vihar": "Mayur Vihar" },
  ];

  let AREAS = [];
  if (currentCity == "Kota") {
    AREAS = kotaArea.map((area) => {
      return {
        key: Object.keys(area)[0],
        value: Object.values(area)[0],
      };
    });
  } else {
    AREAS = delhiArea.map((area) => {
      return {
        key: Object.keys(area)[0],
        value: Object.values(area)[0],
      };
    });
  }

  //   const newHostels = [];
  //   if (res.data) {
  //     Object.entries(res.data).forEach(([k, v]) => {
  //       newHostels.push({ id: k, ...v });
  //     });
  //   }

  useEffect(() => {
    dispatch(getHostels(pageNumber));
  }, [pageNumber]);

  const filterHostels = () => {
    let filterHostels = hostels;
    if (genderValue == "For Boys") {
      filterHostels = hostels.filter((item, index) => {
        return item.gender == "Male";
      });
    } else if (genderValue == "For Girls") {
      filterHostels = hostels.filter((item, index) => {
        return item.gender == "Female";
      });
    }

    if (currentCity) {
      filterHostels = filterHostels.filter((item, index) => {
        return item.location.city == currentCity;
      });
      if (currentLocation) {
        filterHostels = filterHostels.filter((item) => {
          return item.location.area == currentLocation;
        });
      }
    }

    return filterHostels;
  };

  // window.addEventListener("click", (e) => {
  //   if (e.target !== cityRef.current && e.target !== cityMenuRef.current) {
  //     setOpenCityMenu(false);
  //   }
  // });
  //  window.addEventListener("click", (e) => {
  //    if (e.target !== localityRef.current && e.target !== localityMenuRef.current) {
  //      setOpenLocalityMenu(false);
  //    }
  //  });

  const changeCurrentCity = (currCity) => {
    setCurrentCity(currCity);
    setCurrentLocation("");
    setOpenCityMenu(false);
  };

  const changeCurrentLocation = (currLocation) => {
    setCurrentLocation(currLocation);
    setOpenLocalityMenu(false);
  };

  const changeGenderValue = (gender) => {
    setGenderValue(gender);
    setOpenGenderMenu(false);
  };

  const calculateRating = (item) => {
    console.log("calculateRating", item);
    let averageHostelRating = item.ratings.reduce(
      (accum, curr) => accum + curr.rating,
      0
    );
    console.log(averageHostelRating);
    averageHostelRating = averageHostelRating / item.ratings.length;
    averageHostelRating = averageHostelRating.toFixed(2);
    return averageHostelRating;
  };
  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : error ? (
        <div>{error.message}</div>
      ) : (
        <>
          <div className="filter-hostels">
            <div>
              <div
                className="gender-wise"
                onClick={() => setOpenGenderMenu(!openGenderMenu)}
              >
                <div>By Gender</div>
                <IoIosArrowDown />
              </div>
              {openGenderMenu && (
                <div id="gender-wise">
                  {genders.map((gender) => (
                    <div onClick={() => changeGenderValue(gender)}>
                      {gender}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div>
              <div
                className="city-wise"
                ref={cityRef}
                onClick={() => setOpenCityMenu(!openCityMenu)}
              >
                <div>By City</div>
                <IoIosArrowDown />
              </div>
              {openCityMenu && (
                <div id="area-wise" ref={cityMenuRef}>
                  {citiesArr.map((city) => (
                    <div onClick={() => changeCurrentCity(city)}>{city}</div>
                  ))}
                </div>
              )}
            </div>
            <div>
              <div
                className="locality-wise"
                ref={localityRef}
                onClick={() => setOpenLocalityMenu(!openLocalityMenu)}
              >
                <div>By Locality</div>
                <IoIosArrowDown />
              </div>
              {openLocalityMenu && (
                <div ref={localityMenuRef}>
                  {AREAS.map((area) => (
                    <div onClick={() => changeCurrentLocation(area.value)}>
                      {area.value}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          {!currentLocation ? (
            <div id="selected-city">
              AnyWhere in <span>{currentCity}</span>
            </div>
          ) : (
            <div id="selected-location">
              <span> {currentCity}</span>
              {","}
              {currentLocation}
            </div>
          )}
          <div className="hostel-card-wrapper">
            {filterHostels() &&
              filterHostels().map((item, index) => (
                <div
                  className="hostel-card"
                  onClick={() => navigate(`/hostel-details/${item.id}`)}
                >
                  {item.isPremium == true && (
                    <div className="premium">Premium</div>
                  )}

                  {/* {!isNaN(calculateRating(item)) && (
                    <div className="rating">
                      <span>{calculateRating(item)}</span>
                      <AiFillStar />
                    </div>
                  )} */}

                  <div className="hostel-image">
                    <img src={item.uris && item.uris[0]} />
                  </div>
                  <div className="hostel-details">
                    <div className="hostel-address-name">
                      <div className="hostel-name">{item.hostelName}</div>
                      <div className="hostel-address">
                        <span>{item.location.address} </span>
                      </div>
                    </div>
                    <div className="hostel-price">₹ {item.rent.minRent}</div>
                  </div>
                </div>
              ))}
          </div>

          {/* <div className="load-more">
            <button onClick={() => setPageNumber((prevPage) => prevPage + 1)}>
              Load More
            </button>
          </div> */}
        </>
      )}
    </>
  );
}

export default Hostel;
