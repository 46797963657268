import Signup from "./pages/signup/Signup";
import Login from "./pages/login/Login";
import Header from "./components/header/Header";
import Hostel from "./pages/hostels/Hostel";
import AddHostel from "./pages/add-hostel/AddHostel";
import Profile from "./pages/profile/Profile";
import HostelDetails from "./pages/hostel-details/HostelDetails";
import UserSpecificHostels from "./pages/userSpecificHostels/UserSpecificHostels";
import UpdateHostel from "./pages/update-hostel/UpdateHostel";
import Home from "./pages/home/Home";
import Contact from "./pages/contact/Contact";
import { ProtectRoute } from "./components/ProtectRoute";
import SignupWithPhone from "./pages/signup-with-phone/SignupWithPhone";
import { Route, Routes } from "react-router-dom";

function App() {
  console.log("Apppp");
  return (
    <div>
      <Header />

      <Routes>
        <Route element={<ProtectRoute />}>
          <Route path="/profile" element={<Profile />} />
          <Route path="/add-hostel" element={<AddHostel />} />
          <Route path="/profile/my-hostels" element={<UserSpecificHostels />} />
          <Route path="/update-hostel/:hostelId" element={<UpdateHostel />} />
        </Route>
        <Route path="/" element={<Home />} />
        <Route path="/contact/:hostelId" element={<Contact />} />
        <Route path="/hostels" element={<Hostel />} />
        <Route path="/hostel-details/:hostelId" element={<HostelDetails />} />
        <Route path="/signup" element={<Signup />} />
        {/* <Route path="/login" element={<Login />} /> */}
        <Route path="/signup/phone" element={<SignupWithPhone />} />
      </Routes>
    </div>
  );
}

export default App;
