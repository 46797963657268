import React, { useEffect, useState } from "react";
import { FaLessThan } from "react-icons/fa";
import { FaGreaterThan } from "react-icons/fa";
import "./carousel.css";

function Carousel({ images }) {
  console.log("images", images);
  const [currentIndex, setCurrentIndex] = useState(0);
  console.log(images[currentIndex]);
  console.log("currentIndex", currentIndex);
  const nextSlide = () => {
    setCurrentIndex((prevState) => {
      return prevState === images.length - 1 ? 0 : currentIndex + 1;
    });
  };

  const prevSlide = () => {
    setCurrentIndex((prevState) => {
      return prevState === 0 ? images.length - 1 : currentIndex - 1;
    });
  };


    useEffect(() => {
        const autoPlayInterval = setInterval(nextSlide, 3000);
        return () => {
            clearInterval(autoPlayInterval);
        }
    },[currentIndex])
  return (
    <>
      <div className="carousel">
        <div className="slider">
          <div onClick={prevSlide}>
            <FaLessThan />
          </div>
          <img src={images[currentIndex]} alt={`Slide ${currentIndex}`} />
          <div onClick={nextSlide}>
            <FaGreaterThan />
          </div>
        </div>
      </div>
      <div>
        {images.map((val, i) => (
          <div
            id={i === currentIndex ? "selected" : null}
            onClick={() => setCurrentIndex(i)}
          ></div>
        ))}
      </div>
    </>
  );
}

export default Carousel;
