import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";
import logo from "../../assets/logo.svg";
import "./contact.css";
import { useNavigate } from "react-router";

function Contact() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [contactDetails, setContactDetails] = useState(null);
  const params = useParams();
  const getContactDetails = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `${process.env.REACT_APP_REALTIME_DB_URL}/hostels/${params.hostelId}.json`
      );
      setLoading(false);
      setContactDetails(res.data);
      return res.data;
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };
  useEffect(() => {
    getContactDetails();
  }, []);
  return (
    <div>
      <div className="contactDetails">
        <h2>Ashutosh Singh</h2>
        <div className="contact-number">8952911060</div>
        <div className="text">
          Call for assistance in finding hostels at no extra cost.
        </div>
        <p>
          <a href="tel:8952911060">CALL NOW</a>
        </p>
        <div className="form-btn">
          <button>FILL CONTACT FORM</button>
        </div>
        <div className="back-to-home">
          <button onClick={() => navigate("/hostels")}>BACK TO HOME</button>
        </div>
      </div>
    </div>
  );
}

export default Contact;
